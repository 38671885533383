import React from 'react'
import './CallWhatsapp.css'
import HeaderBar from '../HeaderBar/HeaderBar'
import call from '../../Images/call.png'
import whatsapp from '../../Images/whatsapp.png'
import mail from '../../Images/mail.png'




function CallWhatsapp() {
  return (
    <>
      <HeaderBar pagename={"Connect With Us"} />
      <div className="callwhatsapp">
        <div className="call">
          <h3>Call Us On</h3>
          <div className="calldiv">
            <img src={call} alt="" />
            <h4>+91 91377 37536</h4>
          </div>
          <a href="tel:+91 91377 37536">Click To Call</a>
        </div>
        <div className="call">
          <h3>WhatsApp Us On</h3>
          <div className="calldiv">
            <img src={whatsapp} alt="" />
            <h4>+91 91377 37536</h4>
          </div>
          <a href="https://wa.link/eue8bi">Click To WhatsApp</a>
        </div>
        <div className="call">
          <h3>Mail Us On</h3>
          <div className="calldiv">
            <img src={mail} alt="" />
            <h4>Modernindianbistro@gmail.com</h4>
          </div>
          <a href="mailto:Modernindianbistro@gmail.com">Click To Mail</a>
        </div>
      </div>
    </>
  )
}

export default CallWhatsapp
