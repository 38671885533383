import React, { useState } from 'react';
import Logo from '../../Images/mibLogo.png';
import Undi from '../../Images/lineBlack.png'
import { Link, NavLink } from "react-router-dom";

import './Header.css';

function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <>
            <div className="option">
                <div className="topHeader">
                    <ul>
                        <li><Link className='link-style'>
                            <i className="fa-solid fa-phone"></i>
                            <p>+91 91377 37536</p>
                        </Link></li>
                        <li><Link className='link-style'>
                            <i className="fa-solid fa-envelope"></i>
                            <p>Modernindianbistro@gmail.com</p>
                        </Link></li>
                        <li><Link className='link-style'>
                            <i className="fa-solid fa-location-dot"></i>
                            <p>
                                Shop No 06, Shankeshwar heights, Block G, Sector 11, Kharghar, Navi Mumbai, Maharashtra 410210</p>
                        </Link></li>
                    </ul>
                    <div className="socials">
                        <Link to={"https://wa.link/eue8bi"} target='mib'><i className="fa-brands fa-whatsapp"></i></Link>
                        <Link to={"https://www.instagram.com/modern_indian_bistro/"} target='mib'><i className="fa-brands fa-instagram"></i></Link>
                        <Link to={"https://www.facebook.com/profile.php?id=100094230944651"} target='mib'><i className="fa-brands fa-facebook"></i></Link>
                    </div>
                </div>
            </div>
            <div className={isMenuOpen ? "mobileMenu open" : "close"}>
                <li><NavLink to="/" className="navlink" onClick={toggleMenu}>Home</NavLink></li>
                <li><NavLink to="/about" className="navlink" onClick={toggleMenu}>About Us</NavLink></li>
                <li><NavLink to="/menu" className="navlink" onClick={toggleMenu}>Menu</NavLink></li>
                <li><NavLink to="/gallery" className="navlink" onClick={toggleMenu}>Gallery</NavLink></li>
                <li><NavLink to="/reviews" className="navlink" onClick={toggleMenu}>Reviews</NavLink></li>
                <li><NavLink to="/contact" className="navlink" onClick={toggleMenu}>Contact Us</NavLink></li>
                <li><NavLink to="/call" className="navlink" onClick={toggleMenu}>Call / WhatsApp Us</NavLink></li>
                <li><NavLink to="/order" className="navlink" onClick={toggleMenu}>Order Now</NavLink></li>
            </div>
            <div className="mobileTop">
                <div className="phone">
                    <Link to={"tel:+91 91377 37536"}><i className="fa-solid fa-phone"></i></Link>
                    <Link to={"tel:+91 91377 37536"}><p>+91 91377 37536</p></Link>
                </div>
                <div className="icons">
                    <Link to={"https://wa.link/eue8bi"} target='mib'><i className="fa-brands fa-whatsapp"></i></Link>
                    <Link to={"https://www.instagram.com/modern_indian_bistro/"} target='mib'><i className="fa-brands fa-instagram"></i></Link>
                    <Link to={"https://www.facebook.com/profile.php?id=100094230944651"} target='mib'><i className="fa-brands fa-facebook"></i></Link>
                </div>
            </div>
            <header className="header">
                <div className="logo">
                    <NavLink to="/" className="navlink" ><img src={Logo} alt="Logo" /></NavLink>

                    <img src={Undi} alt="line" className='logoLine' />
                </div>
                <div className="hamburger" onClick={toggleMenu}>
                    <i className={isMenuOpen ? "fa-solid fa-times" : "fa-solid fa-bars"}></i>
                </div>
                <div className="navbar">
                    <ul>
                        <li><NavLink to="/" className="navlink" >Home</NavLink></li>
                        <li><NavLink to="/about" className="navlink" >About Us</NavLink></li>
                        <li><NavLink to="/menu" className="navlink" >Menu</NavLink></li>
                        <li><NavLink to="/gallery" className="navlink" >Gallery</NavLink></li>
                        {/* <li><NavLink to="/OurBranches" className="navlink" >Our Branches</NavLink></li> */}
                        <li><NavLink to="/reviews" className="navlink" >Reviews</NavLink></li>
                        <li><NavLink to="/contact" className="navlink" >Contact Us</NavLink></li>
                    </ul>
                </div>
                <div className="book">

                    <button><NavLink to="/call" className="navlink" >Call / WhatsApp Us</NavLink></button>
                    <button className='btn1'><NavLink to="/order" className="navlink" >Order Now</NavLink></button>
                </div>
            </header>
        </>
    );
}

export default Header;
