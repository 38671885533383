import React, { useState } from 'react'
import './Menu.css'
import Line1 from '../../Images/lineBlack.png'
import Line2 from '../../Images/undiWhite.png'
import HeaderBar from '../HeaderBar/HeaderBar'


function MIBChineseVeg() {
  return (
    <div className="menu-container">
      <div className="menu-heading">
        <h2 className='chineseVeg'>MIB Chinese - Veg</h2>
        <img src={Line1} alt="" />
      </div>
      <div className="main-course soup">
        <div className="rowHeading">
          <h2>Soup</h2>
          <img className="line" src={Line2} alt="" />
        </div>
        <div className="row">
          <h3>Veg Clear Soup</h3>
          <h3>Rs.100</h3>
        </div>
        <div className="row">
          <h3>Veg Manchow Soup</h3>
          <h3>Rs.120</h3>
        </div>
        <div className="row">
          <h3>Veg Hot & Sour</h3>
          <h3>Rs.120</h3>
        </div>
        <div className="row">
          <h3>Veg Sweet Corn</h3>
          <h3>Rs.120</h3>
        </div>
      </div>
      <div className="main-course starters">
        <div className="rowHeading">
          <h2>Starters</h2>
          <img className="line" src={Line2} alt="" />
        </div>
        <div className="row">
          <h3>Veg Hot Garlic</h3>
          <h3>Rs.159</h3>
        </div>
        <div className="row">
          <h3>Veg Manchurian</h3>
          <h3>Rs.169</h3>
        </div>
        <div className="row">
          <h3>Veg Chilli</h3>
          <h3>Rs.169</h3>
        </div>
        <div className="row">
          <h3>Crispy Corn</h3>
          <h3>Rs.179</h3>
        </div>
        <div className="row">
          <h3>Paneer 65</h3>
          <h3>Rs.209</h3>
        </div>
        <div className="row">
          <h3>Honey Chilli Potato</h3>
          <h3>Rs.209</h3>
        </div>
        <div className="row">
          <h3>Honey Chilli Potato</h3>
          <h3>Rs.209</h3>
        </div>
        <div className="row">
          <h3>Veg Crispy</h3>
          <h3>Rs.209</h3>
        </div>
        <div className="row">
          <h3>Paneer Chilli</h3>
          <h3>Rs.209</h3>
        </div>
      </div>
      <div className="main-course noodles">
        <div className="rowHeading">
          <h2>Noodles</h2>
          <img className="line" src={Line2} alt="" />
        </div>
        <div className="row">
          <h3>Veg Hakka Noodles</h3>
          <h3>Rs.180</h3>
        </div>
        <div className="row">
          <h3>Veg Garlic Noodles</h3>
          <h3>Rs.200</h3>
        </div>
        <div className="row">
          <h3>Veg Schezwan Noodles</h3>
          <h3>Rs.200</h3>
        </div>
        <div className="row">
          <h3>Veg Singapore Noodles</h3>
          <h3>Rs.210</h3>
        </div>
        <div className="row">
          <h3>Veg Hongkong Noodles</h3>
          <h3>Rs.210</h3>
        </div>
        <div className="row">
          <h3>Veg Triple Noodles</h3>
          <h3>Rs.210</h3>
        </div>
        <div className="row">
          <h3>Veg Chilly Noodles</h3>
          <h3>Rs.210</h3>
        </div>
        <div className="row">
          <h3>Veg Manchurian Noodles</h3>
          <h3>Rs.210</h3>
        </div>
      </div>
      <div className="main-course rice">
        <div className="rowHeading">
          <h2>Rice</h2>
          <img className="line" src={Line2} alt="" />
        </div>
        <div className="row">
          <h3>Veg Fried Rice</h3>
          <h3>Rs.180</h3>
        </div>
        <div className="row">
          <h3>Veg Garlic Fried Rice</h3>
          <h3>Rs.200</h3>
        </div>
        <div className="row">
          <h3>Veg Schezwan Fried Rice</h3>
          <h3>Rs.200</h3>
        </div>
        <div className="row">
          <h3>Veg Combination Rice</h3>
          <h3>Rs.200</h3>
        </div>
        <div className="row">
          <h3>Veg Singapore Rice</h3>
          <h3>Rs.200</h3>
        </div>
        <div className="row">
          <h3>Veg Hongkong Rice</h3>
          <h3>Rs.200</h3>
        </div>
        <div className="row">
          <h3>Paneer Fried Rice</h3>
          <h3>Rs.220</h3>
        </div>
        <div className="row">
          <h3>Veg Triple Fried Rice</h3>
          <h3>Rs.220</h3>
        </div>
      </div>
      <div className="main-course momos">
        <div className="rowHeading">
          <h2>Momos</h2>
          <img className="line" src={Line2} alt="" />
        </div>
        <div className="row">
          <h3>Veg Steamed Momos</h3>
          <h3>Rs.129</h3>
        </div>
        <div className="row">
          <h3>Veg Fried Momos</h3>
          <h3>Rs.149</h3>
        </div>
        <div className="row">
          <h3>Veg Kurkure Momos</h3>
          <h3>Rs.169</h3>
        </div>
        <div className="row">
          <h3>Veg Cheese Corn Momos</h3>
          <h3>Rs.169</h3>
        </div>
        <div className="row">
          <h3>Veg Peri Peri Momos</h3>
          <h3>Rs.200</h3>
        </div>
      </div>
    </div>
  )
}

function MIBChineseNonVeg() {
  return (
    <div className="menu-container">
      <div className="menu-heading">
        <h2 className='chineseNonVeg'>MIB Chinese - Non Veg</h2>
        <img src={Line1} alt="" />
      </div>
      <div className="main-course nsoup">
        <div className="rowHeading">
          <h2>Soup</h2>
          <img className="line" src={Line2} alt="" />
        </div>
        <div className="row">
          <h3>Chicken Clear Soup</h3>
          <h3>Rs.100</h3>
        </div>
        <div className="row">
          <h3>Veg Manchow Soup</h3>
          <h3>Rs.150</h3>
        </div>
        <div className="row">
          <h3>Chicken Hot & Sour</h3>
          <h3>Rs.150</h3>
        </div>
        <div className="row">
          <h3>Veg Sweet Corn</h3>
          <h3>Rs.150</h3>
        </div>
      </div>
      <div className="main-course nstarters">
        <div className="rowHeading">
          <h2>Starters</h2>
          <img className="line" src={Line2} alt="" />
        </div>
        <div className="row">
          <h3>Chicken Manuchurian</h3>
          <h3>Rs.209</h3>
        </div>
        <div className="row">
          <h3>Chicken Chilli</h3>
          <h3>Rs.209</h3>
        </div>
        <div className="row">
          <h3>Chicken 65</h3>
          <h3>Rs.219</h3>
        </div>
        <div className="row">
          <h3>Honey Chilli Chicken</h3>
          <h3>Rs.219</h3>
        </div>
        <div className="row">
          <h3>Chicken Crispy</h3>
          <h3>Rs.219</h3>
        </div>
        <div className="row">
          <h3>Chicken Lollypop</h3>
          <h3>Rs.229</h3>
        </div>
        <div className="row">
          <h3>Chicken Kung Pao</h3>
          <h3>Rs.249</h3>
        </div>
        <div className="row">
          <h3>Dragon Chicken</h3>
          <h3>Rs.369</h3>
        </div>
        <div className="row">
          <h3>Chicken Hot Garlic</h3>
          <h3>Rs.209</h3>
        </div>
      </div>
      <div className="main-course nnoodles">
        <div className="rowHeading">
          <h2>Noodles</h2>
          <img className="line" src={Line2} alt="" />
        </div>
        <div className="row">
          <h3>Egg Hakka Noodles</h3>
          <h3>Rs.160</h3>
        </div>
        <div className="row">
          <h3>Chicken Hakka Noodles</h3>
          <h3>Rs.220</h3>
        </div>
        <div className="row">
          <h3>Chicken Garlic Noodles</h3>
          <h3>Rs.230</h3>
        </div>
        <div className="row">
          <h3>Chicken Schezwan Noodles</h3>
          <h3>Rs.230</h3>
        </div>
        <div className="row">
          <h3>Chicken Singapore Noodles</h3>
          <h3>Rs.230</h3>
        </div>
        <div className="row">
          <h3>Chicken Hongkong Noodles</h3>
          <h3>Rs.230</h3>
        </div>
        <div className="row">
          <h3>Chicken Tripple Noodles</h3>
          <h3>Rs.230</h3>
        </div>
        <div className="row">
          <h3>Chicken Chilly Noodles</h3>
          <h3>Rs.250</h3>
        </div>
        <div className="row">
          <h3>Chicken Manchurian Noodles</h3>
          <h3>Rs.250</h3>
        </div>
      </div>
      <div className="main-course nrice">
        <div className="rowHeading">
          <h2>Rice</h2>
          <img className="line" src={Line2} alt="" />
        </div>
        <div className="row">
          <h3>Egg Fried Rice</h3>
          <h3>Rs.170</h3>
        </div>
        <div className="row">
          <h3>Chicken Fried Rice</h3>
          <h3>Rs.200</h3>
        </div>
        <div className="row">
          <h3>Chicken Garlic Fried Rice</h3>
          <h3>Rs.220</h3>
        </div>
        <div className="row">
          <h3>Chicken Schezwan Fried Rice</h3>
          <h3>Rs.220</h3>
        </div>
        <div className="row">
          <h3>Chicken Singapore Rice</h3>
          <h3>Rs.220</h3>
        </div>
        <div className="row">
          <h3>Chicken Triple Fried Rice</h3>
          <h3>Rs.240</h3>
        </div>
        <div className="row">
          <h3>Chicken Combination Rice</h3>
          <h3>Rs.240</h3>
        </div>
        <div className="row">
          <h3>Chicken Hongkong Rice</h3>
          <h3>Rs.240</h3>
        </div>
        <div className="row">
          <h3>Chicken Chilly Rice</h3>
          <h3>Rs.250</h3>
        </div>
        <div className="row">
          <h3>Chicken Sherpa Rice</h3>
          <h3>Rs.250</h3>
        </div>
      </div>
      <div className="main-course nmomos">
        <div className="rowHeading">
          <h2>Momos</h2>
          <img className="line" src={Line2} alt="" />
        </div>
        <div className="row">
          <h3>Chkicken Steamed Momos</h3>
          <h3>Rs.149</h3>
        </div>
        <div className="row">
          <h3>Chicken Fried Momos</h3>
          <h3>Rs.149</h3>
        </div>
        <div className="row">
          <h3>Chicken Kurkure Momos</h3>
          <h3>Rs.179</h3>
        </div>
        <div className="row">
          <h3>Chicken Cheese Corn Momos</h3>
          <h3>Rs.189</h3>
        </div>
        <div className="row">
          <h3>Chicken Peri Peri Momos</h3>
          <h3>Rs.189</h3>
        </div>
      </div>
    </div>
  )
}

function MIBSpecialCombo() {
  return (
    <div className="menu-container">
      <div className="menu-heading">
        <h2 className='special'>MIB Special Combo Deals</h2>
        <img src={Line1} alt="" />
      </div>
      <div className="main-course main-course2 specialveg">
        <div className="rowHeading">
          <h2>Veg</h2>
          <img className="line" src={Line2} alt="" />
        </div>
        <div className="row">
          <div>
            <h3>Burger & Fries Meal</h3>
            <p>Aloo Tikki Burger Paired With Salted French Fries + Iced Soft Drink</p>
          </div>
          <h3>Rs.149</h3>
        </div>
        <div className="row">
          <div>
            <h3>Italian Combo</h3>
            <p>Garlic Bread. White Sause. Pasta & Chilly Garlic Shots Paired with
              Salted French Fries & Brownie + Iced Soft Drink.
            </p>
          </div>
          <h3>Rs.299</h3>
        </div>
        <div className="row">
          <div>
            <h3>CHINESE BOWL COMBO</h3>
            <p>Bowl with Fried Rice / Noodles + Manchurian Gravy
            </p>
          </div>
          <h3>Rs.149</h3>
        </div>
        <div className="row">
          <div>
            <h3>CHINESE COMBO</h3>
            <p>Manchow Soup with Fried Rice + Manchurian &
              Kurkure Momos Complemented by Home Made Schezwan &
              Mayo Dips Iced Soft Drink
            </p>
          </div>
          <h3>Rs.299</h3>
        </div>
        <div className="row">
          <div>
            <h3>Momos Platter</h3>
            <p>Platter with Steam Momos + Kurkure Momos +
            Chilley Momos +Peri Peri Momos Complemented by Home Made Schezwan & Mayo Dips
            </p>
          </div>
          <h3>Rs.299</h3>
        </div>
      </div>
      <div className="main-course main-course2 specialNonveg">
        <div className="rowHeading">
          <h2>Non Veg</h2>
          <img className="line" src={Line2} alt="" />
        </div>
        <div className="row">
          <div>
            <h3>Burger & Fries Meal</h3>
            <p>Aloo  Chicken Burger Paired With Salted French Fries + Iced Soft Drink</p>
          </div>
          <h3>Rs.199</h3>
        </div>
        <div className="row">
          <div>
            <h3>Italian Combo</h3>
            <p>Garlic Bread. White Sause. Pasta & Chilly Garlic Shots Paired with
              Salted French Fries & Brownie + Iced Soft Drink.
            </p>
          </div>
          <h3>Rs.349</h3>
        </div>
        <div className="row">
          <div>
            <h3>CHINESE BOWL COMBO</h3>
            <p>Bowl with Fried Rice / Noodles + Manchurian Gravy
            </p>
          </div>
          <h3>Rs.169</h3>
        </div>
        <div className="row">
          <div>
            <h3>CHINESE COMBO</h3>
            <p>Manchow Soup with Fried Rice + Manchurian &
              Kurkure Momos Complemented by Home Made Schezwan &
              Mayo Dips Iced Soft Drink
            </p>
          </div>
          <h3>Rs.359</h3>
        </div>
        <div className="row">
          <div>
            <h3>Momos Platter</h3>
            <p>Platter with Steam Momos + Kurkure Momos +
            Chilley Momos +Peri Peri Momos Complemented by Home Made Schezwan & Mayo Dips
            </p>
          </div>
          <h3>Rs.349</h3>
        </div>
      </div>

    </div>
  )
}

function MIBPIzzaVeg() {
  return (
    <div className="menu-container">
      <div className="menu-heading">
        <h2 className='pizzaVeg'>MIB Pizza - Veg</h2>
        <img src={Line1} alt="" />
      </div>
      <div className="main-course pizza">
        <div className="rowHeading">
          <h2>MARGARITA</h2>
          <h3>Pizza Sauce & Cheese</h3>
          <img className="line" src={Line2} alt="" />
        </div>
        <div className="row">
          <h3>Small</h3>
          <h3>Rs.129</h3>
        </div>
        <div className="row">
          <h3>Medium</h3>
          <h3>Rs.199</h3>
        </div>
        <div className="row">
          <h3>Large</h3>
          <h3>Rs.299</h3>
        </div>
      </div> 
      <div className="main-course pizza">
        <div className="rowHeading">
          <h2>CHEESE CORN</h2>
          <h3>Pizza Sauce, Sweet Corn, Cheese</h3>
          <img className="line" src={Line2} alt="" />
        </div>
        <div className="row">
          <h3>Small</h3>
          <h3>Rs.149</h3>
        </div>
        <div className="row">
          <h3>Medium</h3>
          <h3>Rs.239</h3>
        </div>
        <div className="row">
          <h3>Large</h3>
          <h3>Rs.339</h3>
        </div>
      </div> 
      <div className="main-course pizza">
        <div className="rowHeading">
          <h2>VEG EXOTICA</h2>
          <h3>Pizza Sauce, Tomato, Onion, Capsicum, Mushroom, Black Olives</h3>
          <img className="line" src={Line2} alt="" />
        </div>
        <div className="row">
          <h3>Small</h3>
          <h3>Rs.249</h3>
        </div>
        <div className="row">
          <h3>Medium</h3>
          <h3>Rs.359</h3>
        </div>
        <div className="row">
          <h3>Large</h3>
          <h3>Rs.469</h3>
        </div>
      </div> 
      <div className="main-course pizza">
        <div className="rowHeading">
          <h2>FARM FRESH</h2>
          <h3>Pizza Sauce, Cheese, Tomato, Onion, Capsicum, Corn</h3>
          <img className="line" src={Line2} alt="" />
        </div>
        <div className="row">
          <h3>Small</h3>
          <h3>Rs.199</h3>
        </div>
        <div className="row">
          <h3>Medium</h3>
          <h3>Rs.299</h3>
        </div>
        <div className="row">
          <h3>Large</h3>
          <h3>Rs.399</h3>
        </div>
      </div> 
      <div className="main-course pizza">
        <div className="rowHeading">
          <h2>Paneer Tikka</h2>
          <h3>Pizza Sauce, Cheese, Onion, Capsicum, Paneer Tikka, Tandoori Sauce</h3>
          <img className="line" src={Line2} alt="" />
        </div>
        <div className="row">
          <h3>Small</h3>
          <h3>Rs.249</h3>
        </div>
        <div className="row">
          <h3>Medium</h3>
          <h3>Rs.359</h3>
        </div>
        <div className="row">
          <h3>Large</h3>
          <h3>Rs.469</h3>
        </div>
      </div> 
      <div className="main-course pizza">
        <div className="rowHeading">
          <h2>Veg Overloaded</h2>
          <h3>Pizza Sauce, Cheese, Onion, Bell Pepper, Capsicum, Mushroom, Black Olives</h3>
          <img className="line" src={Line2} alt="" />
        </div>
        <div className="row">
          <h3>Small</h3>
          <h3>Rs.349</h3>
        </div>
        <div className="row">
          <h3>Medium</h3>
          <h3>Rs.479</h3>
        </div>
        <div className="row">
          <h3>Large</h3>
          <h3>Rs.619</h3>
        </div>
      </div> 
    </div>
  )
}

function MIBPIzzaNonVeg() {
  return (
    <div className="menu-container">
      <div className="menu-heading">
        <h2 className='pizzaNonveg'>MIB Pizza - Non Veg</h2>
        <img src={Line1} alt="" />
      </div>
      <div className="main-course cpizza">
        <div className="rowHeading">
          <h2>CHICKEN CHEESE</h2>
          <h3>Pizza Sauce, Chicken, Cheese</h3>
          <img className="line" src={Line2} alt="" />
        </div>
        <div className="row">
          <h3>Small</h3>
          <h3>Rs.199</h3>
        </div>
        <div className="row">
          <h3>Medium</h3>
          <h3>Rs.259</h3>
        </div>
        <div className="row">
          <h3>Large</h3>
          <h3>Rs.369</h3>
        </div>
      </div> 
      <div className="main-course cpizza">
        <div className="rowHeading">
          <h2>CHICKEN SHEEKH KABAB</h2>
          <h3>Onion, Jalapeno, Chicken Sheek, Cheese, Pizza Sauce</h3>
          <img className="line" src={Line2} alt="" />
        </div>
        <div className="row">
          <h3>Small</h3>
          <h3>Rs.249</h3>
        </div>
        <div className="row">
          <h3>Medium</h3>
          <h3>Rs.359</h3>
        </div>
        <div className="row">
          <h3>Large</h3>
          <h3>Rs.469</h3>
        </div>
      </div> 
      <div className="main-course cpizza">
        <div className="rowHeading">
          <h2>CHICKEN TIKKA</h2>
          <h3>Chicken Tikka, Onion, Capsicum, Red Peprika, Black Olives, Cheese</h3>
          <img className="line" src={Line2} alt="" />
        </div>
        <div className="row">
          <h3>Small</h3>
          <h3>Rs.299</h3>
        </div>
        <div className="row">
          <h3>Medium</h3>
          <h3>Rs.409</h3>
        </div>
        <div className="row">
          <h3>Large</h3>
          <h3>Rs.519</h3>
        </div>
      </div> 
      <div className="main-course cpizza">
        <div className="rowHeading">
          <h2>CHICKEN PEPPERONI</h2>
          <h3>Bell Pepper, Chicken, Pepperoni, Olives, Cheese</h3>
          <img className="line" src={Line2} alt="" />
        </div>
        <div className="row">
          <h3>Small</h3>
          <h3>Rs.299</h3>
        </div>
        <div className="row">
          <h3>Medium</h3>
          <h3>Rs.399</h3>
        </div>
        <div className="row">
          <h3>Large</h3>
          <h3>Rs.499</h3>
        </div>
      </div> 
      <div className="main-course cpizza">
        <div className="rowHeading">
          <h2>PERI PERI CHICKEN</h2>
          <h3>Tomato, Onion, Capsicum, Olives, Cheese</h3>
          <img className="line" src={Line2} alt="" />
        </div>
        <div className="row">
          <h3>Small</h3>
          <h3>Rs.249</h3>
        </div>
        <div className="row">
          <h3>Medium</h3>
          <h3>Rs.359</h3>
        </div>
        <div className="row">
          <h3>Large</h3>
          <h3>Rs.569</h3>
        </div>
      </div> 
      <div className="main-course cpizza">
        <div className="rowHeading">
          <h2>CHICKEN OVERLOAD</h2>
          <h3>Chicken Sausages, Pepperoni, Chicken Tikka, Red Peprika, Olives, Cheese</h3>
          <img className="line" src={Line2} alt="" />
        </div>
        <div className="row">
          <h3>Small</h3>
          <h3>Rs.399</h3>
        </div>
        <div className="row">
          <h3>Medium</h3>
          <h3>Rs.569</h3>
        </div>
        <div className="row">
          <h3>Large</h3>
          <h3>Rs.819</h3>
        </div>
      </div> 
    </div>
  )
}



function Menu() {
  const [activeComponent, setActiveComponent] = useState('Rice');

  const showComponent = (componentName) => {
    setActiveComponent(componentName);
  };
  return (
    <>
      <HeaderBar pagename={"Menu"} />
      <div className="tabs">
        <button className={activeComponent === 'MIB-Chinese-Veg' ? 'button active' : 'button'} onClick={() => showComponent('MIB-Chinese-Veg')} >MIB Chinese - Veg</button>
        <button className={activeComponent === 'MIB-Chinese-NonVeg' ? 'button active' : 'button'} onClick={() => showComponent('MIB-Chinese-NonVeg')}>MIB Chinese - Non Veg</button>
        <button className={activeComponent === 'MIBSpecialCombo' ? 'button active' : 'button'} onClick={() => showComponent('MIBSpecialCombo')} >MIB Special Combo Deals</button>
        <button className={activeComponent === 'MIBPIzzaVeg' ? 'button active' : 'button'} onClick={() => showComponent('MIBPIzzaVeg')} >MIB Pizza Veg</button>
        <button className={activeComponent === 'MIBPIzzaNonVeg' ? 'button active' : 'button'} onClick={() => showComponent('MIBPIzzaNonVeg')} >MIB Pizza Non Veg</button>
      </div>
      {activeComponent === 'MIB-Chinese-Veg' && <MIBChineseVeg />}
      {activeComponent === 'MIB-Chinese-NonVeg' && <MIBChineseNonVeg />}
      {activeComponent === 'MIBSpecialCombo' && <MIBSpecialCombo />}
      {activeComponent === 'MIBPIzzaVeg' && <MIBPIzzaVeg />}
      {activeComponent === 'MIBPIzzaNonVeg' && <MIBPIzzaNonVeg />}
    </>
  )
}

export default Menu
