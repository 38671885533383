import React, { useState, useEffect } from 'react';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import { Outlet, useNavigation } from 'react-router-dom';
import Preloader from './Preloader/Preloader';
import ScrollTop from './ScrollToTop/Scroll.jsx'

function Layout() {
  const [loading, setLoading] = useState(true);
  const navigation = useNavigation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [navigation]);

  return (
    <>
      <ScrollTop />
      <Header />
      {loading ? <Preloader /> : <Outlet />}
      <Footer />
    </>
  );
}

export default Layout;
