import React from 'react'
import './Contact.css'
import Underline from '../../Images/lineBlack.png'
import Logo from '../../Images/mibLogo.png';
import HeaderBar from '../HeaderBar/HeaderBar';

function Contact() {
  return (
    <>
      
      <HeaderBar pagename={"Contact Us"}/>
      <div className="contactContainer">
        <div className="formbox2">
          <img src={Logo} alt="" />
          
        </div>
        <div className="formbox1">
          <h5>CONNECT WITH US</h5>
          <h2>Send Us A Message</h2>
          <img src={Underline} alt="" />
          <div className="form">
            <form action="">
              <input type="text" placeholder='Your Name' />
              <input type="email" placeholder='Your Email' />
              <input type="tel" placeholder='Your Phone Number' />
              <input type="text" placeholder='Subject' />
              <textarea cols="30" rows="10" placeholder='Write A Message'></textarea>
              <button>Send Message</button>
            </form>
          </div>
        </div>

      </div>
      <div className="mapContainer">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.5519760366687!2d73.06887847520446!3d19.03945398215766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c3b635a55fe7%3A0xd9a11486cb41117f!2sM.I.B%20-%20Modern%20Indian%20Bistro!5e0!3m2!1sen!2sin!4v1725984964332!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='mib'></iframe>
      </div>
    </>
  )
}

export default Contact
