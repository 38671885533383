import React from 'react'
import './OrderNow.css'
import HeaderBar from '../HeaderBar/HeaderBar'
import Swiggy from '../../Images/swiggy.jpg'
import Zomato from '../../Images/zomato.png'
import Logo from '../../Images/mibLogo.png'
import { Link } from 'react-router-dom'



function OrderNow() {
  return (
    <>
      <HeaderBar pagename={"Order Now"} />

      <div className="order-container">
        <div className="party-orders">
          <img src={Logo} alt="" />
          <h2>
            We Accept Party Orders
          </h2>
        </div>
        <div className="order-now">
          <h2>Call Now To Order</h2>
          <h1>+91 91377 37536</h1>
          <h3>Or</h3>
          <h2>Order Online On</h2>
          <div className="online">
            <Link to="https://www.swiggy.com/city/mumbai/m-i-b-shankeshwar-height-n-a-rest722624" target='mib' className="navlink" ><img src={Swiggy} alt="" /></Link>
            <Link to="https://www.zomato.com/mumbai/m-i-b-kharghar-navi-mumbai" target='mib' className="navlink" ><img src={Zomato} alt="" /></Link>
          </div>
        </div>
      </div>



    </>
  )
}

export default OrderNow
