import React from 'react'
import './About.css'
import foodBg from '../../Images/mibBG.jpeg'
import HeaderBar from '../HeaderBar/HeaderBar'
import MIBLogo from '../../Images/mibLogo.png'

function About() {
  return (
    <>
      <HeaderBar pagename={"About Us"} />
      <div className="aboutUsContainer">
        <div className="aboutUsContainer1">
          <img src={MIBLogo} alt="" />
        </div>
        <div className="aboutUsContainer2">
          <p>We started Modern Indian Bistro because we love good food and wanted to share that passion with you. Our chefs carefully prepare every dish using fresh ingredients, making sure each meal is full of flavor and made with care. From crispy noodles to cheesy pizzas, our menu is designed to please everyone, whether you enjoy bold, spicy flavors or more classic comfort foods.<br /><br />The atmosphere at our bistro is cozy and welcoming. We’ve created a space where you can relax and enjoy a meal, whether you’re out with friends, having a family dinner, or grabbing a quick bite. With comfortable seating and a warm vibe, Modern Indian Bistro is the perfect place to spend time and enjoy delicious food.

          </p>
        </div>
      </div>
      <div className="aboutUsInfo">
        <div className="aboutUsInfo1">
          <div className="aboutUsInfoIcon">
            <i class="fa-solid fa-circle"></i>
            <div className="aboutUsInfoContent">
              <h3>Exceptional Quality</h3>
              <p> Every dish is made using the freshest, finest ingredients available, ensuring the highest standard of taste and presentation.
              </p>
            </div>
          </div>
          <div className="aboutUsInfoIcon">
            <i class="fa-solid fa-circle"></i>
            <div className="aboutUsInfoContent">
              <h3>Authentic Flavors</h3>
              <p>Experience the true taste of Indian cuisine with every bite.
              </p>
            </div>
          </div>
          <div className="aboutUsInfoIcon">
            <i class="fa-solid fa-circle"></i>
            <div className="aboutUsInfoContent">
              <h3>Warm Hospitality</h3>
              <p>Our staff ensures a welcoming and delightful dining experience.
              </p>
            </div>
          </div>
          <div className="aboutUsInfoIcon">
            <i class="fa-solid fa-circle"></i>
            <div className="aboutUsInfoContent">
              <h3>Diverse Menu</h3>
              <p>A wide variety of dishes, from traditional classics to contemporary favorites.
              </p>
            </div>
          </div>
          <div className="aboutUsInfoIcon">
            <i class="fa-solid fa-circle"></i>
            <div className="aboutUsInfoContent">
              <h3>Tasty Cuisines</h3>
              <p>Delight in our array of delicious cuisines, each prepared with passion and care to offer a memorable dining experience.
              </p>
            </div>
          </div>
        </div>
        <div className="aboutUsInfo2">
          <img src={foodBg} alt="" />
        </div>
      </div>
    </>
  )
}

export default About
