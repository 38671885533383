import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Layout from './Components/Layout';
import Home from './Components/Home/Home';
import About from './Components/About/About';
import Menu from './Components/Menu/Menu';
import Gallery from './Components/Gallery/Gallery';
import OrderNow from './Components/OrderNow/OrderNow';
import CallWhatsapp from './Components/CallWhatsapp/CallWhatsapp';
import Reviews from './Components/Reviews/Reviews';
import Contact from './Components/Contact/Contact';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './index.css'

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "", element: <Home /> },
      { path: "/about", element: <About /> },
      { path: "/menu", element: <Menu /> },
      { path: "/gallery", element: <Gallery /> },
      { path: "/order", element: <OrderNow /> },
      { path: "/reviews", element: <Reviews /> },
      { path: "/contact", element: <Contact /> },
      { path: "/call", element: <CallWhatsapp /> },
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
