import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../Images/mibLogo.png';
import { NavLink } from 'react-router-dom'
import './Footer.css'

function Footer() {
  return (
    <>
      <div className="footerTopContainer">
        <div className="footerLogoInfo">
          <img src={Logo} alt="" />
          <p>Looking forward to enjoying some scrumptious food along with delectable drinks? </p>
          <div className="footerLogoIcons">
          <Link to={"https://wa.link/eue8bi"} target='mib'><i className="fa-brands fa-whatsapp"></i></Link>
                        <Link to={"https://www.instagram.com/modern_indian_bistro/"} target='mib'><i className="fa-brands fa-instagram"></i></Link>
                        <Link to={"https://www.facebook.com/profile.php?id=100094230944651"} target='mib'><i className="fa-brands fa-facebook"></i></Link>
          </div>
        </div>
      </div>
      <div className="footerContainer">
        <div className="footerBox1">

          {/* <img src={Logo} alt="" />
          <div className="footerIcons">
            <i class="fa-brands fa-instagram"></i>
            <i class="fa-brands fa-facebook"></i>
            <i class="fa-brands fa-youtube"></i>
          </div> */}
          <h2>About Us</h2>
          <p>At MIB, we bring to your table a celebration of flavors that embody the rich culinary heritage of India. Established with a passion for serving authentic and delicious meals.</p>
          <div className="footerIcon1">
            <i class="fa-solid fa-phone"></i>
            <h5>+91 91377 37536</h5>
          </div>
          <div className="footerIcon2">
            <i class="fa-solid fa-envelope"></i>
            <h5>Modernindianbistro@gmail.com</h5>
          </div>
          <div className="footerIcon2">
            <i class="fa-solid fa-location-dot"></i>
            <h5>Shop No 06, Shankeshwar heights, Block G, Sector 11, Kharghar, Navi Mumbai, Maharashtra 410210</h5>
          </div>
        </div>
        <div className="footerBox3">
          <h3>Quick Links</h3>
          <ul >
          <li><NavLink to="/" className="navlink" >Home</NavLink></li>
                <li><NavLink to="/about" className="navlink">About Us</NavLink></li>
                <li><NavLink to="/menu" className="navlink">Menu</NavLink></li>
                <li><NavLink to="/gallery" className="navlink">Gallery</NavLink></li>
                <li><NavLink to="/reviews" className="navlink">Reviews</NavLink></li>
                <li><NavLink to="/contact" className="navlink">Contact Us</NavLink></li>
                <li><NavLink to="/call" className="navlink">Call / WhatsApp Us</NavLink></li>
                <li><NavLink to="/order" className="navlink">Order Now</NavLink></li>
          </ul>
        </div>
        <div className="footerBox2">
          <h3>Opening Hours</h3>
          <div>
            <h4>Mon-Fri</h4>
            <h4>11:00 am - 10:00pm</h4>
          </div>
          <div>
            <h4>Sat-Sun</h4>
            <h4>10:00 am - 11:00pm</h4>
          </div>

        </div>

        <div className="footerBox4">
          <h3>Location</h3>
          <div className='mapBox'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.5519760366687!2d73.06887847520446!3d19.03945398215766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c3b635a55fe7%3A0xd9a11486cb41117f!2sM.I.B%20-%20Modern%20Indian%20Bistro!5e0!3m2!1sen!2sin!4v1725984964332!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='mib'></iframe>
          </div>
        </div>
      </div>
      <div className="footerSubContainer">
        <h3>Made By Himali & Shekhar | All Rights Reserved 2024</h3>
      </div>
    </>
  )
}

export default Footer
