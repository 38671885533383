import React from 'react'
import './Reviews.css'
import Undi from '../../Images/lineBlack.png'
import HeaderBar from '../HeaderBar/HeaderBar'

function Reviews() {
  return (
    <>
      <HeaderBar pagename={"Reviews"}/>
      <div className="reviewsContainer">
        <h5>Reviews</h5>
        <h1>Our Happy Customers</h1>
        <img src={Undi} alt="" />
        <div className="review">
          <div className="reviewBox">
            <i class="fa-regular fa-user"></i>
            <h2>Swarupa Chavan</h2>
            <p>The "M I B Cafe" offers an aesthetically pleasing environment with cozy seating arrangements, elegant and well-balanced lighting, complemented by soft background music.
            </p>
          </div>
          <div className="reviewBox">
            <i class="fa-regular fa-user"></i>
            <h2>Shruta Maurya</h2>
            <p>Very tasty cuisines, especially aloo Tikki burger, Chinese, and egg roll.....yummy food...authentic Chinese....and amazing ambience and good service...
            </p>
          </div>

          <div className="reviewBox">
            <i class="fa-regular fa-user"></i>
            <h2>Vinika Balu</h2>
            <p>Chinese food was awesome.
            Interior is amazing. We get positive vibes after entering.
            </p>
          </div>

          <div className="reviewBox">
            <i class="fa-regular fa-user"></i>
            <h2>Neharika Malviya</h2>
            <p>I had Veg Combo @99... Worth the price. Manchurian was so crispy. They have simple games like ludo, chess, jenga etc that you can play while waiting for your order. Parking a available right outside the place.
            </p>
          </div>

          <div className="reviewBox">
            <i class="fa-regular fa-user"></i>
            <h2>Vinit Suryarao</h2>
            <p>Nice food with great ambience. Chess, ludo, zenga games are also there for u r spending u r waiting time. Great place for hangout.
            </p>
          </div>

          <div className="reviewBox">
            <i class="fa-regular fa-user"></i>
            <h2>Shoaib Patel</h2>
            <p>Small and cozy cafe. Good option to hangout with friends. They do have indoor card games as well.
            </p>
          </div>

          <div className="reviewBox">
            <i class="fa-regular fa-user"></i>
            <h2>Pratik Takawle</h2>
            <p>Nice and peaceful place for having a decent conversation with friends in kharghar
            </p>
          </div>

        <div className="reviewBox">
          <i class="fa-regular fa-user"></i>
          <h2>Kishore Raju</h2>
          <p>We tried the Chinese cuisine, and it tasted good. The restaurant is located next to Ryan School Kharghar
          </p>
        </div>
        <div className="reviewBox">
          <i class="fa-regular fa-user"></i>
          <h2>Suraj Pawar</h2>
          <p>Fantastic food and impeccable service! A truly delightful experience at the cafe. Highly recommend it for anyone seeking a happy and satisfying dining experience.
          </p>
        </div>

        </div>
      </div>
    </>
  )
}

export default Reviews
