import React from 'react';
import './Preloader.css'; // Import CSS for styling
import logo from '../../Images/mibLogo.png'

const Preloader = () => {
  return (
    <div className="preloader">
      <img src={logo} alt="Logo" className="preloader-logo" />
    </div>
  );
};

export default Preloader;
