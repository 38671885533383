import React from 'react'
import './Gallery.css'
import img1 from '../../Images/gridImages/mib1.jpg'
import img2 from '../../Images/mibBG.jpeg'
import img3 from '../../Images/gridImages/mib3.jpg'
import img4 from '../../Images/gridImages/mib4.jpg'
import img5 from '../../Images/gridImages/mib5.jpg'
import img6 from '../../Images/gridImages/mib6.jpg'
import img7 from '../../Images/gridImages/mib7.jpg'
import img8 from '../../Images/gridImages/mib8.jpg'
import img9 from '../../Images/gridImages/mib9.jpg'
import img10 from '../../Images/gridImages/mib10.jpg'
import img11 from '../../Images/gridImages/mib11.jpg'
import img12 from '../../Images/gridImages/mib12.jpg'
import HeaderBar from '../HeaderBar/HeaderBar'



function Gallery() {
  return (
    <>
      <HeaderBar pagename={"Gallery"}/>
      <div className="galleryContainer">
        <div className="galleryBox" id="gbox-1">
          <img src={img1} alt="" />
        </div>
        <div className="galleryBox" id="gbox-2">
          <img src={img2} alt="" />
        </div>
        <div className="galleryBox" id="gbox-3">
          <img src={img3} alt="" />
        </div>
        <div className="galleryBox" id="gbox-4">
          <img src={img4} alt="" />
        </div>
        <div className="galleryBox" id="gbox-5">
          <img src={img5} alt="" />
        </div>
        <div className="galleryBox" id="gbox-6">
          <img src={img6} alt="" />
        </div>
        <div className="galleryBox" id="gbox-7">
          <img src={img7} alt="" />
        </div>
        <div className="galleryBox" id="gbox-8">
          <img src={img8} alt="" />
        </div>
        <div className="galleryBox" id="gbox-9">
          <img src={img9} alt="" />
        </div>
        <div className="galleryBox" id="gbox-10">
          <img src={img10} alt="" />
        </div>
        <div className="galleryBox" id="gbox-11">
          <img src={img11} alt="" />
        </div>
        <div className="galleryBox" id="gbox-12">
          <img src={img12} alt="" />
        </div>

      </div>
    </>
  )
}

export default Gallery
