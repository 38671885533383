import React from 'react'
import MIBLogo2 from '../../Images/mibLogo2.png'
import AboutImg from '../../Images/mibBG.jpeg'
import './Home.css'
import Undi from '../../Images/undiWhite.png'
import Undi2 from '../../Images/lineBlack.png'
import Line2 from '../../Images/newUndi.png'
import cold from '../../Images/cold.jpg'
import vegCheesBalls from '../../Images/cheeseballs.jpg'
import fries from '../../Images/fries.jpg'
import burger from '../../Images/burger.jpg'
import sandwich from '../../Images/sandwich.jpg'
import pasta from '../../Images/pasta.jpg'
import roll from '../../Images/roll.jpg'
import chickenBall from '../../Images/chickennuggets.jpg'
import chickenfries from '../../Images/chickenFries.jpg'
import chickenBurger from '../../Images/chickenburger.jpg'
import chickenSandwich from '../../Images/chickenSandwich.jpg'
import chickenPasta from '../../Images/chickenpast.jpg'

import { Link } from 'react-router-dom'


function Home() {
  return (
    <>
      <main>
        <div className="container">
          <div className="box1">
          <Link to={"https://wa.link/eue8bi"} target='mib'><i className="fa-brands fa-whatsapp"></i></Link>
                        <Link to={"https://www.instagram.com/modern_indian_bistro/"} target='mib'><i className="fa-brands fa-instagram"></i></Link>
                        <Link to={"https://www.facebook.com/profile.php?id=100094230944651"} target='mib'><i className="fa-brands fa-facebook"></i></Link>
          </div>
          <div className="box2">
            <img className='logoImg' src={MIBLogo2} alt="" />
            <h2>Modern Indian Bistro</h2>
            <p>Welcome to Modern Indian Bistro, your cozy spot in Kharghar for tasty food and great vibes. We love mixing traditional Indian flavors with a modern twist, offering everything from delicious Indo-Chinese dishes to all-time favorites like pizza, burgers, and fries.</p>
          </div>
          <div className="box3">
            <button className='vertical-button'> <Link to="https://maps.app.goo.gl/rKrcCJRge1b8Qnhj8" target='_blank'> Get Location</Link> </button>
            <img src={Undi} alt="" />
          </div>
        </div>
      </main >
      <div className="aboutContainer">
        <div className="aboutImg">
          <img src={AboutImg} alt="" />
        </div>
        <div className="aboutInfo">
          <h3>Modern Indian Bistro</h3>
          <p>At Modern Indian Bistro, we bring you the best of Indo-Chinese flavors, blended with a range of international favorites. From our sizzling noodles and spicy fried rice to cheesy pizzas, juicy burgers, and creamy shakes, there's something here for everyone!</p>
          <p>Our restaurant was started with a passion for good food, and we aim to serve meals that make you feel happy and satisfied. Whether you like spicy noodles or cheesy pizzas, we’ve got something for everyone. At Modern Indian Bistro, we want you to enjoy not just the food, but the whole experience. With our comfortable seating and friendly service, it’s the perfect place to relax, hang out with friends, or enjoy a meal with your family.</p>
          <button><Link to="/about">KNOW MORE ABOUT US</Link></button>
        </div>
      </div>
      <div className="heading-of-menu">
        <h2>Our Menu</h2>
        <img src={Undi2} alt="" />
      </div>

      <div className="menu-container-home">
        <div className="main-course-home shake">
          <div className="rowHeadingHome">
            <h2>Shakes</h2>
            <img className="line" src={Line2} alt="" />
          </div>
          <div className="row">
            <h3>Cold Coffee</h3>
            <h3>Rs.129</h3>
          </div>
          <div className="row">
            <h3>Kuka</h3>
            <h3>Rs.129</h3>
          </div>
          <div className="row">
            <h3>Kuka With Crush</h3>
            <h3>Rs.139</h3>
          </div>
          <div className="row">
            <h3>Chocolate Shake</h3>
            <h3>Rs.139</h3>
          </div>
          <div className="row">
            <h3>Bubble Gum Shake</h3>
            <h3>Rs.139</h3>
          </div>
          <div className="row">
            <h3>Oreo Shake</h3>
            <h3>Rs.149</h3>
          </div>
          <div className="row">
            <h3>Kitkat Shake</h3>
            <h3>Rs.149</h3>
          </div>
          <div className="row">
            <h3>Chocolate Thunder Shake</h3>
            <h3>Rs.169</h3>
          </div>
        </div>
        <div className="main-course-home mocktails">
          <div className="rowHeadingHome">
            <h2>Mocktails</h2>
            <img className="line" src={Line2} alt="" />
          </div>
          <div className="row">
            <h3>Fresh Lime Soda</h3>
            <h3>Rs.99</h3>
          </div>
          <div className="row">
            <h3>Virgin Mint Mojito</h3>
            <h3>Rs.119</h3>
          </div>
          <div className="row">
            <h3>Lemon Mint Mojito</h3>
            <h3>Rs.119</h3>
          </div>
          <div className="row">
            <h3>Blue Lagoon Mojito</h3>
            <h3>Rs.119</h3>
          </div>
          <div className="row">
            <h3>Bubble Gum Mojito</h3>
            <h3>Rs.139</h3>
          </div>
        </div>
        <div className="main-course-home coffee">
          <div className="rowHeadingHome">
            <h2>Beverages Hot</h2>
            <img className="line" src={Line2} alt="" />
          </div>
          <div className="row">
            <h3>Regular Coffee</h3>
            <h3>Rs.69</h3>
          </div>
          <div className="row">
            <h3>Espresso</h3>
            <h3>Rs.89</h3>
          </div>
          <div className="row">
            <h3>Americano</h3>
            <h3>Rs.109</h3>
          </div>
          <div className="row">
            <h3>Cappuccino</h3>
            <h3>Rs.119</h3>
          </div>
          <div className="row">
            <h3>Latte</h3>
            <h3>Rs.129</h3>
          </div>
          <div className="row">
            <h3>Doppio - Double Espresso</h3>
            <h3>Rs.139</h3>
          </div>
          <div className="row">
            <h3>Mocha - Chocolate Coffe</h3>
            <h3>Rs.139</h3>
          </div>
          <div className="row">
            <h3>MIB Cutting</h3>
            <h3>Rs.20</h3>
          </div>
          <div className="row">
            <h3>Lemon Tea</h3>
            <h3>Rs.40</h3>
          </div>
          <div className="row">
            <h3>Green Tea</h3>
            <h3>Rs.40</h3>
          </div>
          <div className="row">
            <h3>Black Tea</h3>
            <h3>Rs.20</h3>
          </div>
        </div>
      </div>

      <div className="home-menu-container">
      <div className="home-menu-box">
          <img src={cold} alt="" className='home-menu-box-img' />
          <div className="home-menu-box-content">
            <h2>Cold Beverages</h2>
            <div className="menu-column">
              <div className="row">
                <h3>Coke</h3>
                <h3>Rs.49</h3>
              </div>
              <div className="row">
                <h3>Thumps Up   </h3>
                <h3>Rs.49</h3>
              </div>      
              <div className="row">
                <h3>Sprite</h3>
                <h3>Rs.49</h3>
              </div>         
              <div className="row">
                <h3>Frooti</h3>
                <h3>Rs.49</h3>
              </div>         
            </div>

          </div>
        </div>
        <div className="home-menu-box">
          <img src={vegCheesBalls} alt="" className='home-menu-box-img' />
          <div className="home-menu-box-content">
            <h2>Appetizer</h2>
            <div className="menu-column">
              <div className="row">
                <h3>Cheese Corn Balls</h3>
                <h3>Rs.119</h3>
              </div>
              <div className="row">
                <h3>Garlic Bread</h3>
                <h3>Rs.119</h3>
              </div>
              <div className="row">
                <h3>Cheese Chilly Toast</h3>
                <h3>Rs.149</h3>
              </div>
              <div className="row">
                <h3>Cheese Garlic Bread</h3>
                <h3>Rs.149</h3>
              </div>
              <div className="row">
                <h3>Veg Spring Roll</h3>
                <h3>Rs.199</h3>
              </div>
            </div>

          </div>
        </div>
        <div className="home-menu-box">
          <img src={fries} alt="" className='home-menu-box-img' />
          <div className="home-menu-box-content">
            <h2>Fries</h2>
            <div className="menu-column">
              <div className="row">
                <h3>Classic Fries</h3>
                <h3>Rs.149</h3>
              </div>
              <div className="row">
                <h3>Peri Peri Fries</h3>
                <h3>Rs.199</h3>
              </div>
              <div className="row">
                <h3>Cheesy Fries</h3>
                <h3>Rs.199</h3>
              </div>
              <div className="row">
                <h3>Mexican Fries</h3>
                <h3>Rs.199</h3>
              </div>
              <div className="row">
                <h3>Overloaded Fries</h3>
                <h3>Rs.249</h3>
              </div>
            </div>

          </div>
        </div>
        <div className="home-menu-box">
          <img src={burger} alt="" className='home-menu-box-img' />
          <div className="home-menu-box-content">
            <h2>Burger</h2>
            <div className="menu-column">
              <div className="row">
                <h3>Aloo Tikki Burger</h3>
                <h3>Rs.69</h3>
              </div>
              <div className="row">
                <h3>Classic Burger</h3>
                <h3>Rs.149</h3>
              </div>
              <div className="row">
                <h3>Supreme Cheese Burger</h3>
                <h3>Rs.149</h3>
              </div>
              {/* <div className="row">
                <h3>Paneer Burger</h3>
                <h3>Rs.199</h3>
              </div>
              <div className="row">
                <h3>Mexican Cheese Burger</h3>
                <h3>Rs.199</h3>
              </div> */}
              <div className="row">
                <h3>Hot & Spicy Burger</h3>
                <h3>Rs.199</h3>
              </div>
              <div className="row">
                <h3>MIB Special Burger</h3>
                <h3>Rs.299</h3>
              </div>
            </div>

          </div>
        </div>
        <div className="home-menu-box">
          <img src={sandwich} alt="" className='home-menu-box-img' />
          <div className="home-menu-box-content">
            <h2>Sandwich</h2>
            <div className="menu-column">
              <div className="row">
                <h3>Veg Sandwich</h3>
                <h3>Rs.149</h3>
              </div>
              <div className="row">
                <h3>Grilled Cheese Sandwich</h3>
                <h3>Rs.199</h3>
              </div>
              <div className="row">
                <h3>CLub Sandwich</h3>
                <h3>Rs.249</h3>
              </div>
              
            </div>

          </div>
        </div>
        <div className="home-menu-box">
          <img src={pasta} alt="" className='home-menu-box-img' />
          <div className="home-menu-box-content">
            <h2>Pasta</h2>
            <div className="menu-column">
              <div className="row">
                <h3>Arrabiata Pasta</h3>
                <h3>Rs.249</h3>
              </div>
              <div className="row">
                <h3>Alfredo Pasta</h3>
                <h3>Rs.249</h3>
              </div>
              <div className="row">
                <h3>Pink Sauce Pasta</h3>
                <h3>Rs.249</h3>
              </div>
              
            </div>

          </div>
        </div>
        <div className="home-menu-box">
          <img src={roll} alt="" className='home-menu-box-img' />
          <div className="home-menu-box-content">
            <h2>Roll</h2>
            <div className="menu-column">
              <div className="row">
                <h3>Veg Roll</h3>
                <h3>Rs.99</h3>
              </div>
              <div className="row">
                <h3>Veg Dragon Roll</h3>
                <h3>Rs.149</h3>
              </div>      
            </div>

          </div>
        </div>
        <div className="home-menu-box">
          <img src={chickenBall} alt="" className='home-menu-box-img' />
          <div className="home-menu-box-content">
            <h2>Appetizer - Non Veg</h2>
            <div className="menu-column">
              <div className="row">
                <h3>Chicken Spring Roll</h3>
                <h3>Rs.99</h3>
              </div>
              <div className="row">
                <h3>Chicken Nuggets - 6pc</h3>
                <h3>Rs.149</h3>
              </div>      
              <div className="row">
                <h3>Bruschetta</h3>
                <h3>Rs.199</h3>
              </div>      
            </div>

          </div>
        </div>
        <div className="home-menu-box">
          <img src={chickenfries} alt="" className='home-menu-box-img' />
          <div className="home-menu-box-content">
            <h2>Fries - Non Veg</h2>
            <div className="menu-column">
              <div className="row">
                <h3>Chicken Cheese Fries</h3>
                <h3>Rs.199</h3>
              </div>
              <div className="row">
                <h3>Chicken Nugget Fries</h3>
                <h3>Rs.199</h3>
              </div>      
              <div className="row">
                <h3>Chicken Mexican Fries</h3>
                <h3>Rs.199</h3>
              </div>      
              <div className="row">
                <h3>Chicken Overloaded Fries</h3>
                <h3>Rs.299</h3>
              </div>      
            </div>

          </div>
        </div>
        <div className="home-menu-box">
          <img src={chickenBurger} alt="" className='home-menu-box-img' />
          <div className="home-menu-box-content">
            <h2>Burger - Non Veg</h2>
            <div className="menu-column">
              <div className="row">
                <h3>Classic Chicken Burger</h3>
                <h3>Rs.149</h3>
              </div>
              <div className="row">
                <h3>Chicken Supreme Burger</h3>
                <h3>Rs.199</h3>
              </div>      
              <div className="row">
                <h3>Hot & Spicy Chicken Burger</h3>
                <h3>Rs.199</h3>
              </div>      
              <div className="row">
                <h3>Mexican Chicken Burger</h3>
                <h3>Rs.199</h3>
              </div>      
              <div className="row">
                <h3>MIB Special Chicken Burgefr</h3>
                <h3>Rs.399</h3>
              </div>      
            </div>

          </div>
        </div>
        <div className="home-menu-box">
          <img src={chickenSandwich} alt="" className='home-menu-box-img' />
          <div className="home-menu-box-content">
            <h2>Sandwich - Non Veg</h2>
            <div className="menu-column">
              <div className="row">
                <h3>Chicken Sandwich</h3>
                <h3>Rs.199</h3>
              </div>
              <div className="row">
                <h3>Chicken Cheese Sandwich</h3>
                <h3>Rs.249</h3>
              </div>      
              <div className="row">
                <h3>Chicken Club Sandwich</h3>
                <h3>Rs.299</h3>
              </div>         
            </div>

          </div>
        </div>
        <div className="home-menu-box">
          <img src={chickenPasta} alt="" className='home-menu-box-img' />
          <div className="home-menu-box-content">
            <h2>Pasta - Non Veg</h2>
            <div className="menu-column">
              <div className="row">
                <h3>Chicken Arrabiata Pasta</h3>
                <h3>Rs.299</h3>
              </div>
              <div className="row">
                <h3>Chicken Alfredo Pasta</h3>
                <h3>Rs.299</h3>
              </div>      
              <div className="row">
                <h3>Chicken Pink Sauce Pasta</h3>
                <h3>Rs.299</h3>
              </div>         
            </div>

          </div>
        </div>
        

      </div>
    </>
  )
}

export default Home
