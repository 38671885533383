import React from 'react'
import './HeaderBar.css'
import undi from '../../Images/newUndi.png'

const HeaderBar = (props) => {
  return (
    <div className="headerBar">
        <h1>{props.pagename}</h1>
        <img src={undi} alt="" />
      </div>
  )
}

export default HeaderBar